import React, {useContext, useReducer} from 'react';
import left from '../imgs/left.svg';
import right from '../imgs/right.svg';
import EditContext from "../contexts/EditContext";
import DateContext from "../contexts/DateContext";
import {date_input_formatter} from "../utils/date-format-utils";
import {days} from "../enums/days";
import {date_change_handler, date_xcrementer} from "../utils/date-controller-utils";
import LoadingContext from "../contexts/LoadingContext";
import {display_loader} from "../utils/loader-utils";

// Force rerender

const DateController = () => {
    const [, ] = useContext(EditContext);
    const [date, setDate] = useContext(DateContext);
    const [, ] = useContext(LoadingContext);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const date_xcrementer_handler = (date, action, setDate) => {
        display_loader();
        date_xcrementer(date, action, setDate);
        
forceUpdate();
    }

    return (
        <div id="date-controller">
            <img src={left} alt="left arrow" onClick={() => date_xcrementer_handler(date, "previous", setDate)}/>
            <div id="date-info">
                <span id="current-day">{days[date.getDay()]}</span>
                <input type="date" value={date_input_formatter(date)} onChange={(e) => date_change_handler(e, setDate)}/>
            </div>
            <img src={right} alt="right-arrow" onClick={() => date_xcrementer_handler(date, "next", setDate)}/>
        </div>
    );
};

export default DateController;
