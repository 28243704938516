import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import Table from "./Table";
import ChartContext from "../contexts/ChartContext";
import WaitListToggle from "../contexts/WaitListToggle";
import WaitList from "./WaitList";
import CopierContext from "../contexts/CopierContext";
import SearchContext from "../contexts/SearchContext";
import VersionContext from "../contexts/VersionContext";
import InfoContext from "../contexts/InfoContext";
import { getAppVersion } from "../utils/lamba-api";
import { close_loader } from "../utils/loader-utils";
import ErrorContext from "../contexts/ErrorContext";
import Notification from "./Notification";
import EditContext from "../contexts/EditContext";
import Edit from "./Edit";

const Chart = () => {

    const [chart,] = useContext(ChartContext);
    const [waitList,] = useContext(WaitListToggle);
    const [copier, setCopier] = useContext(CopierContext);
    const [info, setInfo] = useContext(InfoContext);
    const [version] = useContext(VersionContext);
    const [error, setError] = useContext(ErrorContext);
    const [serverVersion, setServerVersion] = useState("");
    const [edit, setEdit] = useContext(EditContext);
    const [editData, setEditData] = useState(null)
    const [,] = useContext(SearchContext);
    const [trigger, setTrigger] = useState(false);

    // useEffect(() => {
    //     setTimeout(() => {
    //         let els = document.getElementsByClassName("table");
    //         Array.from(els).forEach(e => {
    //             if (!(e.id.includes("table"))) {
    //                 e.style.backgroundColor = "#721e15"
    //             }
    //         })
    //     }, 500)
    // }, [trigger, chart])

    useEffect(() => {
        const getServerVersion = async () => {
            return await getAppVersion();
        }
        if (info) {
            let value = null;
            getServerVersion().then(r => {
                value = r.data.body.response
                setServerVersion(value);

                setError({ notification: true, error: false })
                close_loader();
            })
                .catch(er => {
                    setError({ notification: true, error: true, message: "Unknown Error: Check Logs" })
                });
        }
    }, [info])

    return (<div id="chart-container" onClick={(e) => e.stopPropagation()}>
        <div className="seating-container">
            {chart !== null && chart?.seatingOne ? chart.seatingOne.map((e, i) => {
                return (<Table data={e} key={`Blueberry-${i}`} seating="1" setEditData={setEditData} />)
            }) : <></>}
        </div>
        <div className="seating-container">
            {chart !== null && chart?.seatingTwo ? chart.seatingTwo.map((e, i) => {
                return (<Table data={e} key={`Snozzberry-${i}`} seating="2" setEditData={setEditData} />)
            }) : <></>}
        </div>
        {waitList ? <WaitList /> : <></>}
        {copier.active ? (<div id="number-box" onClick={(e) => e.stopPropagation()}>
            <textarea value={copier.data} onChange={(e) => {
            }} onClick={(e) => e.stopPropagation()} />
            <button onClick={() => setCopier({ active: false })}>CLOSE</button>
        </div>) : null}
        {
            info ? <div id="version-box" onClick={(e) => e.stopPropagation()}>
                <span>{`Your app version: ${version}`}</span>
                <span>{`Most recent app version: ${serverVersion}`}</span>
                <button onClick={() => setInfo(false)}>CLOSE</button>
            </div> : null
        }
        {
            edit ? <Edit data={editData} thisEdit={setEdit} seating={editData.seating} /> : <></>
        }
        {
            error.notification ? <Notification error={error.error} message={error.message} /> : null
        }


    </div>
    )
};

export default Chart;
