import React, {useState} from 'react';
import { login } from '../utils/wait-list-api';
import { close_loader, display_loader } from '../utils/loader-utils';


const Reauthorize = (props) => {

    const [input, setInput] = useState("")

    const verify = async (e) => {
        if (input !== "") {
            // VERIFY
			let encoded = btoa(encodeURI(input));
            display_loader();
            await login(encoded)
                .then(e => {
                    close_loader();
                    if (e.body.success) {
                        props.setRevalidate(false);
                        setTimeout(() => {
                            props.action.current === "save" ? props.save() : props.delete();
                        }, 250)
                    } else {
                        setTimeout(() => {
                            alert("incorrect");
                        }, 250)
                    }
                })
        }
    }


    return (
        <div id="reauthorize">
            Please re-renter your password
            <br />
            <input id="reauth-input" type="password" onChange={(e) => setInput(e.target.value)} value={input}>
            </input>
            <button className="button-54" onClick={verify}>Enter</button>
        </div>
    );
};

export default Reauthorize;
