import React, { useContext, useEffect, useState, useRef } from 'react';
import { getCookie } from '../utils/cookies';
import { jwtDecode } from 'jwt-decode';
import { edit_window_positioner } from "../utils/dom-manager";
import DateContext from "../contexts/DateContext";
import EditContext from "../contexts/EditContext";
import { date_fetch_formatter } from "../utils/date-format-utils";
import { deleteRez, putRez } from "../utils/lamba-api";
import LoadingContext from "../contexts/LoadingContext";
import ChartContext from "../contexts/ChartContext";
import { extractNumbers } from "../utils/misc-utils";
import ToggleContext from "../contexts/ToggleContext";
import { close_loader, display_loader } from "../utils/loader-utils";
import LoginContext from "../contexts/LoginContext";
import ErrorContext from "../contexts/ErrorContext";
import { login } from '../utils/wait-list-api';
import Reauthorize from './Reauthorize';

const Edit = (props) => {

    const [date, setDate] = useContext(DateContext);
    const [, setEdit] = useContext(EditContext);
    const [,] = useContext(LoadingContext);
    const [,] = useContext(ChartContext);
    const [toggle, setToggle] = useContext(ToggleContext);
    const [, setAuthenticated] = useContext(LoginContext);
    const [, setError] = useContext(ErrorContext);

    const [dateObject, setDateObject] = useState(date);

    const [originalName, setOriginalName] = useState("");
    const [originalPeople, setOriginalPeople] = useState("");
    const [originalSeating, setOriginalSeating] = useState(props.seating);
    const [originalPhone, setOriginalPhone] = useState("");
    const [originalTables, setOriginalTables] = useState([props.data.table[0].toString()]);
    const [originalInputFormattedDate,] = useState(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`);
    const [originalNotes, setOriginalNotes] = useState("");
    const [originalConfirmed, setOriginalConfirmed] = useState(false);
    const [originalRepeat, setOriginalRepeat] = useState(false);

    const [newName, setNewName] = useState("");
    const [newPeople, setNewPeople] = useState("");
    const [newPeopleFormatted, setNewPeopleFormatted] = useState("");
    const [newSeating, setNewSeating] = useState(props.seating);
    const [newPhone, setNewPhone] = useState("");
    const [newTables, setNewTables] = useState([props.data.table[0].toString()]);
    const [newInputFormattedDate, setNewInputFormattedDate] = useState(originalInputFormattedDate);
    const [newNumberFormatted, setNewNumberFormatted] = useState("");
    const [newConfirmed, setNewConfirmed] = useState(false);
    const [newRepeat, setNewRepeat] = useState(false);
    const [newNotes, setNewNotes] = useState("");

    const [revalidate, setRevalidate] = useState(false);

    const [checkboxState, setCheckboxState] = useState(null);

    const [deleteButton, setDeleteButton] = useState(false);
    const [saveButton, setSaveButton] = useState(false);

    const reauthorize_action = useRef(null);


    useEffect(() => {
        if (originalTables !== null) {
            let checkboxState = [];
            for (let i = 1; i < 11; i++) {
                let thisBox = {
                    state: props.data?.id ? props.data.table.includes(i.toString()) : newTables[0] == i.toString(),
                    table: i
                };
                checkboxState.push(thisBox);
            }
            // @ts-ignore
            setCheckboxState(checkboxState);
        }
    }, []);

    useEffect(() => {
        if (props.data.id === null) {
            setDeleteButton(false);
            setSaveButton(false);
        } else {
            setDeleteButton(true);
        }
    }, [])

    useEffect(() => {
        if (props.data.id === null) {
            setNewTables([props.data.table[0].toString()])
            setOriginalTables([props.data.table[0].toString()])
        } else {
            let { name, people, seating, phone, notes, table } = props.data;

            setOriginalName(name);
            setNewName(name);

            setOriginalPeople(people === "0" ? "" : people);
            setNewPeople(people === "0" ? "" : people);

            if (props.data.time !== undefined) {
                if (props.data.time === "6:00") {
                    setOriginalSeating("1");
                } else {
                    setNewSeating("2");
                }
            } else {
                setOriginalSeating(seating);
                setNewSeating(seating);
            }

            if (props.data.confirmed !== undefined) {
                setOriginalConfirmed(props.data.confirmed === "true");
                setNewConfirmed(props.data.confirmed === "true");
            } else {
                setOriginalConfirmed(false);
                setNewConfirmed(false);
            }

            if (props.data.repeat !== undefined) {
                setOriginalRepeat(props.data.repeat === "true");
                setNewRepeat(props.data.repeat === "true");
            } else {
                setOriginalRepeat(false);
                setNewRepeat(false);
            }

            setOriginalPhone(phone === "0" ? "" : phone);
            setNewPhone(phone === "0" ? "" : phone);

            setOriginalNotes(notes === " " ? "" : notes);
            setNewNotes(notes === " " ? "" : notes);

            setOriginalTables(table);
            setNewTables(table);

        }
    }, [])

    useEffect(() => {
        edit_window_positioner()
    }, [])


    const dateChangeHandler = (e) => {
        setNewInputFormattedDate(e.target.value);
        if (e.target.value !== originalInputFormattedDate) {
            setSaveButton(true)
        }

        const newDateData = {
            month: Number.parseInt(e.target.value.substring(5, 7)),
            day: Number.parseInt(e.target.value.substring(8, 10)),
            year: Number.parseInt(e.target.value.substring(0, 4))
        };

        const newDate = new Date();
        newDate.setFullYear(newDateData.year);
        newDate.setDate(newDateData.day);
        newDate.setMonth(newDateData.month - 1);
        setDateObject(newDate);
    }

    const nameChangeHandler = (e) => {
        setNewName(e.target.value);
        if (e.target.value !== originalName) {
            setSaveButton(true)
        }
    }

    useEffect(() => {
        let formattedNumber = [];
        let extractedCharacters = extractNumbers(newPhone);
        for (let i = 0; i < extractedCharacters.length; i++) {
            if (i === 0) {
                formattedNumber.push("(");
            }
            if (i === 3) {
                formattedNumber.push(")");
                formattedNumber.push(" ");
            }
            if (i === 6) {
                formattedNumber.push("-");
            }
            if (i === 10) {
                formattedNumber.push("-");
            }
            formattedNumber.push(extractedCharacters[i]);
        }
        setNewNumberFormatted(formattedNumber.join(""));
    }, [newPhone]);

    useEffect(() => {
        let extractedNumbers = newPeople.split("").filter(e => e !== "p").join("");
        setNewPeopleFormatted(`${extractedNumbers}p`);
    }, [newPeople])

    const phoneChangeHandler = (e) => {
        setNewPhone(e.target.value);
        if (e.target.value !== originalPhone) {
            setSaveButton(true)
        }
    }

    const notesChangeHandler = (e) => {
        setNewNotes(e.target.value);
        if (e.target.value !== originalNotes) {
            setSaveButton(true)
        }
    }

    const seatingChangeHandler = (e) => {
        setNewSeating(e.target.value);
        if (e.target.value !== originalSeating) {
            setSaveButton(true)
        }
    }

    const peopleChangeHandler = (e) => {
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            let length = e.target.value.length;
            let substringed = e.target.value.substring(0, length - 1);
            setNewPeople(substringed);
            if (substringed !== originalPeople) {
                setSaveButton(true);
            }
        } else {
            setNewPeople(e.target.value);
        }
        setSaveButton(true);
    }

    const tableChangeHandler = (e) => {
        if (e.state === false) {
            let currentTables = newTables;
            currentTables.push(e.table);
            currentTables = currentTables.map(a => Number.parseInt(a)).sort((a, b) => a < b ? -1 : a > b ? 1 : 0).map(a => a.toString());
            setNewTables(currentTables);
            if (currentTables !== originalTables) {
                setSaveButton(true)
            }
        } else {
            let currentTables = newTables.filter(a => a !== e.table.toString()).map(e => e.toString());
            setNewTables(currentTables);
            let tablesDifferent = false;
            for (let i = 0; i < Math.max(currentTables.length, originalTables.length); i++) {
                try {
                    if (currentTables[i] !== originalTables[i]) {
                        tablesDifferent = true;
                        break;
                    }
                } catch (err) {
                    tablesDifferent = true;
                    break;
                }
            }
            if (tablesDifferent) {
                setSaveButton(true)
            }
        }
    }

    const object_packager = () => {
        let api_action;
        if (props.data.id === null) {
            api_action = "create";
        } else {
            if (newInputFormattedDate !== originalInputFormattedDate) {
                api_action = "date-edit";
            } else {
                api_action = "edit";
            }
        }

        return {
            name: newName === "" ? "No Name" : newName,
            people: newPeople === "" ? "0" : newPeople.split("").filter(e => e !== "p").join(""),
            phone: newPhone === "" ? "0" : extractNumbers(newPhone),
            notes: newNotes === "" ? " " : newNotes,
            seating: newSeating,
            repeat: newRepeat.toString(),
            confirmed: newConfirmed.toString(),
            date: date_fetch_formatter(dateObject),
            table: newTables.map(e => e.toString()),
            id: api_action === "create" ? Date.now() + Math.random().toString(36).substring(2, 9) : props.data.id,
            oldDate: date_fetch_formatter(date),
            action: api_action,
            dateObject: dateObject
        };
    }

    const save_reservation = async (e) => {
        // e.stopPropagation();
        console.log(e);
        if (e) {
            e.stopPropagation();
        }
        display_loader();
        let packedObject = object_packager();

        // CHECK TOKEN B4 HTTP REQUEST

        let revalidate = false
        let token = getCookie('lola-sesh')

        if (token === "") {
            setRevalidate(true);
            reauthorize_action.current = "save";
            close_loader();
            return
        }

        const decoded = jwtDecode(token);

        let currentTime = Date.now();

        let valid_token = decoded?.exp * 1000 > currentTime;

        console.log(valid_token);

        if (valid_token) {
            // do nothing
        } else {
            revalidate = true;
        }

        if (revalidate) {
            setRevalidate(true);
            reauthorize_action.current = "save";
            close_loader();
            return
        }



        let response = await putRez(packedObject);
        if (response.data.status !== String(401)) {

            if (response.data.status !== String(200)) {
                setError({ notification: true, error: true, message: "There was an error, contact IT (Dylan) (lmao)" })
            } else {
                setError({ notification: true, error: false })
            }
        } else {
            setError({ notification: true, error: true, message: "Security Check" })
            setAuthenticated(false);
        }
        if (dateObject !== date) {
            setDate(dateObject);
            setEdit(false);
            props.thisEdit(false);
        } else {
            setToggle(!toggle);
            setEdit(false);
            props.thisEdit(false);
        }
    }

    const cancelEditHandler = (e) => {
        e.stopPropagation();
        setEdit(false);
        props.thisEdit(false);
    }

    const confirmChangeHandler = (e) => {
        setNewConfirmed(e.target.checked);
        if (originalConfirmed !== e.target.checked) {
            setSaveButton(true);
        }
    }

    const repeatChangeHandler = (e) => {
        setNewRepeat(e.target.checked);
        console.log(originalRepeat, e.target.checked);
        if (originalRepeat !== e.target.checked) {
            setSaveButton(true);
        }
    }

    const deleteButtonHandler = async (e) => {
        close_loader()
        // e.stopPropagation();
        console.log(e);
        if (e) {
            e.stopPropagation();
        }
        // CHECK TOKEN B4 HTTP REQUEST

        let revalidate = false
        let token = getCookie('lola-sesh')

        if (token === "") {
            setRevalidate(true);
            reauthorize_action.current = "delete";
            close_loader();
            return
        }

        const decoded = jwtDecode(token);

        let currentTime = Date.now();

        let valid_token = decoded?.exp * 1000 > currentTime;

        console.log(valid_token);

        if (valid_token) {
            // do nothing
        } else {
            revalidate = true;
        }

        if (revalidate) {
            setRevalidate(true);
            reauthorize_action.current = "delete";
            close_loader();
            return
        }

        // eslint-disable-next-line no-restricted-globals
        let loader = document.getElementById('loader');
        while (loader.style.display === "block") {
            
        }
        let confirm1 = confirm("delete reservation?");
        if (confirm1) {
            display_loader()
            let response = await deleteRez(props.data.id, dateObject);
            if (response.data.status !== String(401)) {

                if (response.data.status !== String(200)) {
                    setError({ notification: true, error: true, message: "There was an error, contact IT (Dylan) (lmao)" })
                } else {
                    setError({ notification: true, error: false })
                }
            } else {
                setError({ notification: true, error: true, message: "Security Check" })
                alert("Reservation Update Failed. Please try again")
                setAuthenticated(false);
            }
            setEdit(false);
            props.thisEdit(false);
            setToggle(!toggle);
        }
    }

    // if (revalidate) {
    //     return (
    //         <
    //     )
    // }

    return (


        <div id="edit-wrapper" onClick={(e) => e.stopPropagation()}>
            {revalidate ? <Reauthorize setRevalidate={setRevalidate} save={save_reservation} delete={deleteButtonHandler} action={reauthorize_action} /> : null}
            <div id="edit-container" onClick={(e) => e.stopPropagation()}>
                <input type="text" placeholder="Name" value={newName} onChange={(e) => nameChangeHandler(e)} />
                <input type="date" value={newInputFormattedDate} onChange={(e) => dateChangeHandler(e)} />
                <input id="phone-input" type="tel" placeholder="Phone" value={newNumberFormatted}
                    onChange={(e) => phoneChangeHandler(e)} />
                <textarea placeholder="notes" value={newNotes} onChange={(e) => notesChangeHandler(e)} />
                <div id="confirmed-people-container">
                    <input id="guests-input" type="tel" placeholder="# of Guests" value={`${newPeopleFormatted}`}
                        onChange={(e) => peopleChangeHandler(e)} />
                    <div id="confirmed-container">
                        <span>Repeat</span>
                        <input type="checkbox" checked={newRepeat} onChange={(e) => repeatChangeHandler(e)} />
                    </div>
                    <div id="confirmed-container">
                        <span>Confirmed</span>
                        <input type="checkbox" checked={newConfirmed} onChange={(e) => confirmChangeHandler(e)} />
                    </div>
                </div>
                <div id="seating-input">
                    <fieldset>
                        <legend style={{ textAlign: "center" }}>Seating</legend>
                        <div id="seating-radio-main-container">
                            <div className="seating-radio-container">
                                <span>First </span> <input name="seating" id="1" value="1" checked={newSeating === "1"}
                                    type="radio" onChange={(e) => seatingChangeHandler(e)} />
                            </div>
                            <div className="seating-radio-container">
                                <span>Second </span> <input name="seating" id="2" value="2" checked={newSeating === "2"}
                                    type="radio" onChange={(e) => seatingChangeHandler(e)} />
                            </div>

                        </div>
                    </fieldset>
                </div>
                <div id="tables-input">
                    <fieldset>
                        <legend style={{ textAlign: "center" }}>Tables</legend>
                        <div id="checkbox-container">
                            {checkboxState !== null ? checkboxState.map(e => (<div
                                className="table-checkbox"
                                key={`tbl-checkbox-${e.table}`}>
                                <span>{e.table}</span>
                                <input
                                    className="table-change-checkbox"
                                    type="checkbox"
                                    checked={e.state}
                                    value={e.table.toString()}
                                    onChange={(event) => {
                                        tableChangeHandler(e)
                                        setCheckboxState(checkboxState.map((obj) => {
                                            switch (obj.table) {
                                                case e.table:
                                                    obj.state = !obj.state;
                                            }
                                            return obj;
                                        }))
                                    }}
                                />
                            </div>)) : <></>}

                        </div>
                    </fieldset>
                </div>
                <div id="edit-button-container">
                    {deleteButton ? <button id="delete-button" onClick={deleteButtonHandler}>
                        DELETE
                    </button> : <></>}

                    <button id="cancel-button" onClick={cancelEditHandler}>
                        CANCEL
                    </button>
                    {saveButton ? <button id="save-button" onClick={save_reservation}>
                        SAVE
                    </button> : <></>}
                </div>
            </div>
        </div>);
};

export default Edit;
