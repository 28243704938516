import React, { useContext, useEffect, useState, useRef, memo } from 'react';
import Edit from "./Edit";
import EditContext from "../contexts/EditContext";
import ChartContext from "../contexts/ChartContext";
import confirmed from "../imgs/verified.svg"
import repeat from "../imgs/repeat.svg"


const Table = (props) => {
    const [chart,] = useContext(ChartContext);

    const [name, setName] = useState(null);
    const [people, setPeople] = useState(null);
    const [tables, setTables] = useState(null);
    const [tableNumber, setTableNumber] = useState(null);
    const [edit, setEdit] = useContext(EditContext);
    const [thisEdit, setThisEdit] = useState(false);
    const [tableClass, setTableClass] = useState("");
    const [thisTable, setThisTable] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [delay, setDelay] = useState(Math.ceil(2));
    const [style, setStyle] = useState(null)
    const [isAlive, setIsAlive] = useState(false);

    const oldHex = useRef(null);
    const nextHex = useRef(null);
    const targetHex = useRef(null);
    const startTime = useRef(null);
    const STOP = useRef(false);


    const hexOptions = [
        "Red",
        "Orange",
        "Gold",
        "Yellow",
        "Chartreuse",
        "LimeGreen",
        "DeepSkyBlue",
        "Blue",
        "Indigo",
        "Violet"
    ];



    let interval_id = useRef(null);

    function createHex() {
        return hexOptions[Math.floor(Math.random() * hexOptions.length)];
    }


    useEffect(() => {
        if (props.data.id !== null) {

            setThisTable(document.getElementById(props.data.seating + "table" + props.data.table[0]));
            setStyle({ backgroundColor: createHex() })
        } else {
            setStyle(null);
        }
    }, [thisTable, chart])

    useEffect(() => {

        if (thisTable !== null && props.data.id !== null) {

            if (interval_id.current) {
                clearInterval(interval_id.current);
                interval_id.current = null;
            }

            if (!nextHex.current && !oldHex.current) {
                nextHex.current = createHex();
                oldHex.current = nextHex.current;
            }

            setTimeout(() => {
                if (nextHex.current !== null) {
                    oldHex.current = nextHex.current;
                    nextHex.current = createHex();


                    while (oldHex.current === nextHex.current) {
                        nextHex.current = createHex();
                    }

                    setStyle({ backgroundColor: nextHex.current })
                }
                interval_id.current = setInterval(() => {
                    if (nextHex.current !== null) {
                        if (STOP.current) return;
                        oldHex.current = nextHex.current;
                        nextHex.current = createHex();


                        while (oldHex.current === nextHex.current) {
                            if (STOP.current) return;
                            nextHex.current = createHex();
                        }
                        setStyle({ backgroundColor: nextHex.current })
                    }
                }, Math.random() * 1000 + 500);
            }, 500);

        }

        return () => {
            setTimeout(() => {
                if (interval_id.current) {
                    STOP.current = true;
                    clearInterval(interval_id.current);
                    interval_id.current = null;
                    const elapsed = Date.now() - startTime.current;
                }
            }, 500)
        };
    }, [thisTable, props.data.id, chart]);

    useEffect(() => {
        if (typeof props.data.table[0] === "string") {
            STOP.current = false;
        } else {
            setStyle({ backgroundColor: "#721e15" })
            STOP.current = true;
        }
    }, [chart])

    const id = useRef(null);

    useEffect(() => {
        id.current = setInterval(() => {
            // make sure the app isn't being a little bitch.
            if (props.data.id !== null) {
                STOP.current = false;
            } else {
                if (style !== null && style.backgroundColor !== "#721e15") {
                    setStyle({ backgroundColor: "#721e15" })
                }

                if (style === null) {
                    setStyle({ backgroundColor: "#721e15" })
                }
                STOP.current = true;
            }

            if (props.data.id === null && (style === null || style.backgroundcolor !== "#721e15")) {
                setStyle({ backgroundColor: "#721e15" })
            }

        }, 1000)

        return () => {
            // setTimeout(() => {
            //     if (id.current) {
            //         clearInterval(id.current);
            //     }
            // }, 500)


            if (id.current) {
                clearInterval(id.current);
            }
        }
    }, [chart])

    useEffect(() => {
        if (props.data.id !== null) {
            setName(props.data.name);
            setPeople(props.data.people);
            setTables(props.data.table);
        } else {
            setName("");
            setPeople("");
            setTables(props.data.table);
        }
        setTableNumber(props.data.table[0]);
    }, [chart])

    useEffect(() => {
        let string = "table ";
        if (props.data.id === null) {
            string += "table-open ";
        } else {
            string += "table-reserved ";
        }
        if (props.data.confirmed !== undefined) {
            if (props.data.confirmed === "true") {
                string += "table-confirmed "
            }
        }
        if (thisEdit) {
            string += "editing ";
        }
        setTableClass(string);
    }, [chart, thisEdit])


    const openEditHandler = (e) => {
        e.stopPropagation();
        setEdit(!edit);
        setThisEdit(!edit);
        let editObj = {
            ...props.data,
            seating: props.seating
        }
        props.setEditData(editObj);
    }

    return (
        <div id={props.data.id !== null ? props.seating + "table" + props.data.table[0] : props.seating + props.data.table[0]} className={tableClass} style={style} onClick={(e) => openEditHandler(e)}>

            <div className="confirm-repeat-container">
                {
                    props.data?.confirmed === "true" ? <img className="confirmed" src={confirmed} /> : null
                }

                {
                    props.data?.repeat === "true" ? <img className="repeat" src={repeat} /> : null
                }
            </div>


            <span className="table-info-name">
                {props.data.id !== null ? props.data.notes === "" || props.data.notes === " " ? name : `${name}***` : name}
            </span>
            <span className="table-info-people">
                {people === null || people === "" ? "" : `${people}p`}
            </span>

            <div className="table-info-2" >
                {
                    tables === null ? <span>{`T${tableNumber}`}</span> : tables.map((e, i) => {
                        return (
                            <span key={`Cherry-${i}`}>
                                {`T${e}`}
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Table;
