import React, {
	useEffect,
	useState
} from 'react';
import ActivatedTablesContext from './contexts/ActivatedTablesContext';
import ChartContext from "./contexts/ChartContext";
import DateController from "./components/DateController";
import Header from "./components/Header";
import Chart from "./components/Chart";
import {
	body_resizer,
	chart_repositioner,
	login_wrapper_sizer,
	reposition_date_controller
} from "./utils/dom-manager";
import DateContext from "./contexts/DateContext";
import {
	fetch_reservations,
} from "./utils/reservation-formatter";
import EditContext from "./contexts/EditContext";
import FunctionSwitch from "./components/FunctionSwitch";
import LoadingContext from "./contexts/LoadingContext";
import Loader from "./components/Loader";
import ToggleContext from "./contexts/ToggleContext";
import WaitListToggle from "./contexts/WaitListToggle";
import CopierContext from "./contexts/CopierContext";
import SearchContext from "./contexts/SearchContext";
import { close_loader } from "./utils/loader-utils";
import Login from "./components/Login";
import LoginContext from "./contexts/LoginContext";
import { verifyToken } from "./utils/wait-list-api";
import VersionContext from "./contexts/VersionContext";
import InfoContext from "./contexts/InfoContext";
import ErrorContext from "./contexts/ErrorContext";
import { getTodaysRez } from './utils/lamba-api';

const App = () => {

	const [chart, setChart] = useState(null);
	const [activatedTables, setActivatedTables] = useState(null);
	const [date, setDate] = useState(new Date());
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(true);
	const [waitList, setWaitList] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [copier, setCopier] = useState({
		active: false,
		data: ""
	});
	const [authenticated, setAuthenticated] = useState(false);
	const [checking, setChecking] = useState(true);
	const [search, setSearch] = useState(false);
	const [version] = useState("1.1.4");
	const [info, setInfo] = useState(false);
	const [error, setError] = useState({
		notification: true, error: false,
		message: ""
	})

	// useEffect(() => {
	// setChart(format_reservations(data));
	// fetch_reservations(date, setChart);
	// }, [])

	useEffect(() => {
		// let thisDate = date;
		// thisDate.setFullYear(2024);
		// setDate(thisDate);
		// fetch_reservations(thisDate, setChart).then(r => setLoading(false));
	}, [])

	useEffect(() => {

		const resizeHandler = () => {
			chart_repositioner();
		}

		addEventListener('resize', resizeHandler);

		return () => {
			window.removeEventListener('resize', resizeHandler);
		}
	})

	useEffect(() => {
		const vizHandler = () => {
			if (document.visibilityState === "visible") {
				verifyToken()
					.then(r => {
						if (r.data.body.success) {
							setAuthenticated(true);
							setChecking(false);
						} else {
							setAuthenticated(false);
							setChecking(false);
						}
					})
					.catch(err => {
						setAuthenticated(false);
						setChecking(false);
					})
					.finally(a => {
						setTimeout(() => {
							close_loader()
						}, 350);
					})
			} else {
				console.log("Page is out of focus!");
			}
		}

		addEventListener("visibilitychange", vizHandler);

		return () => {
			removeEventListener("visibilitychange", vizHandler)
		}
	})

	useEffect(() => {
		if (authenticated) {
			fetch_reservations(date, setChart)
				.then((e) => {
					if (e.data.data.status === String(401)) {
						// setError({notification: true, error: true, message: "Security Check"})
						setAuthenticated(false);
					}
					if (e.data.data.status === String(200)) {
						// setError({notification: true, error: false, message: ""})
					} else {
						// setError({notification: true, error: true, message: "Unknown Error: Check Logs"})
					}
				})
				.catch(err => {
					setAuthenticated(false);
					document.cookie = "lola-sesh=''"
					// setError({notification: true, error: true, message: "Unknown Error: Check Logs"})
				})
				.finally(f => {
					close_loader();
				})
		}
	}, [date, toggle, authenticated])

	useEffect(() => {
		verifyToken()
			.then(r => {
				if (r.data.body.success) {
					setAuthenticated(true);
					setChecking(false);
				} else {
					setAuthenticated(false);
					setChecking(false);
				}
			})
			.catch(err => {
				setAuthenticated(false);
				setChecking(false);
			})
			.finally(a => {
				setTimeout(() => {
					close_loader()
				}, 350);
			})
	}, [])

	useEffect(() => {
		setTimeout(() => {
			close_loader();
		}, 350);
	}, [chart])

	useEffect(() => {
		body_resizer();
		if (authenticated) {
			chart_repositioner();
			reposition_date_controller();
		} else if (!checking) {
			login_wrapper_sizer();
		}
	}, [authenticated, checking])

	return (<ChartContext.Provider value={[chart, setChart]}>
		<DateContext.Provider value={[date, setDate]}>
			<ActivatedTablesContext.Provider value={[activatedTables, setActivatedTables]}>
				<EditContext.Provider value={[edit, setEdit]}>
					<LoadingContext.Provider value={[loading, setLoading]}>
						<ToggleContext.Provider value={[toggle, setToggle]}>
							<WaitListToggle.Provider value={[waitList, setWaitList]}>
								<CopierContext.Provider value={[copier, setCopier]}>
									<LoginContext.Provider value={[authenticated, setAuthenticated]}>
										<SearchContext.Provider value={[search, setSearch]}>
											<VersionContext.Provider value={[version]}>
												<InfoContext.Provider value={[info, setInfo]}>
													<ErrorContext.Provider value={[error, setError]}>
														<Loader />
														<div id="app-container">
															{checking ? <></> : authenticated ? (<>
																<FunctionSwitch />
																<Header />
																<Chart />
																<DateController />
															</>) : (<Login />)}
														</div>
													</ErrorContext.Provider>
												</InfoContext.Provider>
											</VersionContext.Provider>
										</SearchContext.Provider>
									</LoginContext.Provider>
								</CopierContext.Provider>
							</WaitListToggle.Provider>
						</ToggleContext.Provider>
					</LoadingContext.Provider>
				</EditContext.Provider>
			</ActivatedTablesContext.Provider>
		</DateContext.Provider>
	</ChartContext.Provider>);
};

export default App;
